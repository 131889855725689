import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import RadioButtonGroup from './RadioButtonGroup'; // Adjust the import path as needed


import 'swiper/css';
import 'swiper/css/pagination';


import main from "../images/women/rsort24/main.jpg";
import image1 from "../images/women/rsort24/JONI_SLIM_BOOT_ECRU_TANK_CREAM_2.jpg";
import image2 from "../images/women/rsort24/ALMA_SKIRT_BLACK_LONG_SLEEVE_BOAT_NECK_BLACK_1.jpg";
import image2hover from "../images/women/rsort24/ALMA_SKIRT_BLACK_LONG_SLEEVE_BOAT_NECK_BLACK_1_hover.jpg";
import image3 from "../images/women/rsort24/ESME_DRESS_LT_FOUNTAIN_1.jpg";
import image4 from "../images/women/rsort24/TAYLOR_BARREL_VINTAGE_LIGHT_2.jpg";
import image5 from "../images/women/rsort24/HEPBURN_WIDE_LEG_BLACK_2.jpg";
import image6 from "../images/women/rsort24/JONI_SLIM_BOOT_ECRU_TANK_CREAM_1.jpg";
import image7 from "../images/women/rsort24/ZOIE_WIDE_LEG_CERULEAN_TURTLENECK_BLUE_1-1.jpg";
import image8 from "../images/women/rsort24/ALMA_SKIRT_BLACK_LONG_SLEEVE_BOAT_NECK_BLACK_2.jpg";
import image9 from "../images/women/rsort24/JONI_SLIM_BOOT_ECRU_TANK_CREAM_3.jpg";
import image10 from "../images/women/rsort24/TURTLENECK_CHAROCAL_1-1.jpg";
import image11 from "../images/women/rsort24/DRUE_STRAIGHT_DAYDREAM_LONG_SLEEVE_BOAT_NECK_LIGHT_GREY_2.jpg";
import image12 from "../images/women/rsort24/ENORA_CIGARETTE_VINTAGE_LIGHT_LONG_SLEEVE_BOAT_NECK_BLACK.jpg";
import image13 from "../images/women/rsort24/ASRA_SKIRT_ECRU_LISETTE_SHIRT_MANILLA_2.jpg";
import image14 from "../images/women/rsort24/ALMA_SKIRT_BLACK_LONG_SLEEVE_BOAT_NECK_BLACK_3.jpg";
import image15 from "../images/women/rsort24/DRUE_STRAIGHT_DAYDREAM_LONG_SLEEVE_BOAT_NECK_LIGHT_GREY_1-1.jpg";
import image16 from "../images/women/rsort24/ESME_DRESS_LT_FOUNTAIN_2.jpg";
import image17 from "../images/women/rsort24/ANDI_LTFOUNTAIN_VIKAJACKET_FOUNTAIN_002.jpg";
import image18 from "../images/women/rsort24/DRUE27_VAPOR_012.jpg";
import image19 from "../images/women/rsort24/LUCILASHORT15_CLEARSKY_001.jpg";
import image20 from "../images/women/rsort24/ESMEAPRONDRESS_FOUNTAIN_002.jpg";
import image21 from "../images/women/rsort24/AGNI_BLUEPOPLIN_FAYECROPPED_BLUEPOPLIN_001.jpg";
import image22 from "../images/women/rsort24/PAYTONMINIDRESS_BRIGHTMID_007.jpg";
import image23 from "../images/women/rsort24/LUCILA33_FLAXLINEN_ZIAVEST_FLAXLINEN_HAMPTONSHIRT_FLAXLINEN_008.jpg";
import image24 from "../images/women/rsort24/ALMAMINI_ECRU_005.jpg";
import image25 from "../images/women/rsort24/HEPBURN32_MANILLAKNIT_LONGSLEEVEBOATNECK_CREAM_002.jpg";
import image26 from "../images/women/rsort24/LUCILA33_CLEARSKY_008.jpg";
import image27 from "../images/women/rsort24/TAYLOR27_SUNBLEACHED_005.jpg";
import image28 from "../images/women/rsort24/DESIBOOT_FOUNTAIN_BABYTEE_WHITE_002.jpg";
import image42 from "../images/women/rsort24/ZOIESHORT_VAPOR_001-1.jpg";
import image35 from "../images/women/rsort24/ZOIE32_POOLSIDECARGO_002-1.jpg";
import image41 from "../images/women/rsort24/THEA_RAVELLO_005-1.jpg";
import image31 from "../images/women/rsort24/PATTI31_EASTBAY_SIMONE_WHITEPOPLIN_023-1.jpg";
import image43 from "../images/women/rsort24/MARAANKLE_BLUECURRENT_LONGSLEEVEBOATNECK_LIGHTGREY_024-1.jpg";
import image46 from "../images/women/rsort24/LUCILASHORT15_WHITECARGO_004-1.jpg";
import image29 from "../images/women/rsort24/HEPBURNLOWRISE30__RAVELLOCUFFED_001-1.jpg";
import image36 from "../images/women/rsort24/HEPBURNKNITSHORT_MANILLA_003-1.jpg";
import image44 from "../images/women/rsort24/HEPBURN32_WHITE_003-1.jpg";
import image34 from "../images/women/rsort24/HEPBURN27_ALPINEGREEN_ESSENTIALTEE_ALPINEGREEN_006-1.jpg";
import image45 from "../images/women/rsort24/GWEN_FLAX_001-1.jpg";
import image38 from "../images/women/rsort24/ESMEMIDIDRESS_WHITE_002-1.jpg";
import image37 from "../images/women/rsort24/ENORA_VINTAGELIGHT_ESSENTIALTEE_MANILLA_024-1.jpg";
import image30 from "../images/women/rsort24/DRUE32_FOUNTAINSHREDDED_002-1.jpg";
import image40 from "../images/women/rsort24/DAPHNEMIDIDRESS_WHITE_004-1.jpg";
import image39 from "../images/women/rsort24/COLETTADRESS_WHITE_004-1.jpg";
import image33 from "../images/women/rsort24/BOXERSHORT_WHITEPOPLIN_MONTAUKSHIRT_WHITE-POPLIN_008-1.jpg";
import image32 from "../images/women/rsort24/BOXERSHORT_ALPINEGREEN_ESSENTIALCAMI_APLINEGREEN_004-1.jpg";







import image3hover from "../images/women/rsort24/ESME_DRESS_LT_FOUNTAIN_1_hover.jpg";
import image4hover from "../images/women/rsort24/TAYLOR_BARREL_VINTAGE_LIGHT_2_hover.jpg";
import image5hover from "../images/women/rsort24/HEPBURN_WIDE_LEG_BLACK_2_hover.jpg";
import image6hover from "../images/women/rsort24/JONI_SLIM_BOOT_ECRU_TANK_CREAM_1_hover.jpg";
import image7hover from "../images/women/rsort24/ZOIE_WIDE_LEG_CERULEAN_TURTLENECK_BLUE_1-1_hover.jpg";
import image9hover from "../images/women/rsort24/JONI_SLIM_BOOT_ECRU_TANK_CREAM_3_hover.jpg";
import image10hover from "../images/women/rsort24/TURTLENECK_CHAROCAL_1-1_hover.jpg";
import image11hover from "../images/women/rsort24/DRUE_STRAIGHT_DAYDREAM_LONG_SLEEVE_BOAT_NECK_LIGHT_GREY_2_hover.jpg";
import image12hover from "../images/women/rsort24/ENORA_CIGARETTE_VINTAGE_LIGHT_LONG_SLEEVE_BOAT_NECK_BLACK_hover.jpg";
import image13hover from "../images/women/rsort24/ASRA_SKIRT_ECRU_LISETTE_SHIRT_MANILLA_2_hover.jpg";
import image14hover from "../images/women/rsort24/ALMA_SKIRT_BLACK_LONG_SLEEVE_BOAT_NECK_BLACK_3_hover.jpg";
import image15hover from "../images/women/rsort24/DRUE_STRAIGHT_DAYDREAM_LONG_SLEEVE_BOAT_NECK_LIGHT_GREY_1-1_hover.jpg";

import image17hover from "../images/women/rsort24/ANDI_LTFOUNTAIN_VIKAJACKET_FOUNTAIN_002_hover.jpg";
import image18hover from "../images/women/rsort24/DRUE27_VAPOR_012_hover.jpg";
import image19hover from "../images/women/rsort24/LUCILASHORT15_CLEARSKY_001_hover.jpg";
import image20hover from "../images/women/rsort24/ESMEAPRONDRESS_FOUNTAIN_002_hover.jpg";
import image21hover from "../images/women/rsort24/AGNI_BLUEPOPLIN_FAYECROPPED_BLUEPOPLIN_001_hover.jpg";
import image22hover from "../images/women/rsort24/PAYTONMINIDRESS_BRIGHTMID_007_hover.jpg";
import image23hover from "../images/women/rsort24/LUCILA33_FLAXLINEN_ZIAVEST_FLAXLINEN_HAMPTONSHIRT_FLAXLINEN_008_hover.jpg";
import image24hover from "../images/women/rsort24/ALMAMINI_ECRU_005_hover.jpg";
import image25hover from "../images/women/rsort24/HEPBURN32_MANILLAKNIT_LONGSLEEVEBOATNECK_CREAM_002_hover.jpg";
import image26hover from "../images/women/rsort24/LUCILA33_CLEARSKY_008_hover.jpg";
import image27hover from "../images/women/rsort24/TAYLOR27_SUNBLEACHED_005_hover.jpg";
import image28hover from "../images/women/rsort24/DESIBOOT_FOUNTAIN_BABYTEE_WHITE_002_hover.jpg";
import image42hover from "../images/women/rsort24/ZOIESHORT_VAPOR_001-1_hover.jpg";
import image35hover from "../images/women/rsort24/ZOIE32_POOLSIDECARGO_002-1_hover.jpg";
import image41hover from "../images/women/rsort24/THEA_RAVELLO_005-1_hover.jpg";
import image31hover from "../images/women/rsort24/PATTI31_EASTBAY_SIMONE_WHITEPOPLIN_023-1_hover.jpg";
import image43hover from "../images/women/rsort24/MARAANKLE_BLUECURRENT_LONGSLEEVEBOATNECK_LIGHTGREY_024-1_hover.jpg";
import image46hover from "../images/women/rsort24/LUCILASHORT15_WHITECARGO_004-1_hover.jpg";
import image29hover from "../images/women/rsort24/HEPBURNLOWRISE30__RAVELLOCUFFED_001-1_hover.jpg";
import image36hover from "../images/women/rsort24/HEPBURNKNITSHORT_MANILLA_003-1_hover.jpg";
import image44hover from "../images/women/rsort24/HEPBURN32_WHITE_003-1_hover.jpg";
import image34hover from "../images/women/rsort24/HEPBURN27_ALPINEGREEN_ESSENTIALTEE_ALPINEGREEN_006-1_hover.jpg";
import image45hover from "../images/women/rsort24/GWEN_FLAX_001-1_hover.jpg";
import image38hover from "../images/women/rsort24/ESMEMIDIDRESS_WHITE_002-1_hover.jpg";
import image37hover from "../images/women/rsort24/ENORA_VINTAGELIGHT_ESSENTIALTEE_MANILLA_024-1_hover.jpg";
import image30hover from "../images/women/rsort24/DRUE32_FOUNTAINSHREDDED_002-1_hover.jpg";
import image40hover from "../images/women/rsort24/DAPHNEMIDIDRESS_WHITE_004-1_hover.jpg";
import image39hover from "../images/women/rsort24/COLETTADRESS_WHITE_004-1_hover.jpg";
import image33hover from "../images/women/rsort24/BOXERSHORT_WHITEPOPLIN_MONTAUKSHIRT_WHITE-POPLIN_008-1_hover.jpg";
import image32hover from "../images/women/rsort24/BOXERSHORT_ALPINEGREEN_ESSENTIALCAMI_APLINEGREEN_004-1_hover.jpg";

import menPDF from "../images/women/rsort24/WOMEN-RESORT24-LOOKBOOK.pdf";



export default function women_ss24() {


  return (
    <>

      <section id="content">
        <div className="container-fluid">

          <div className="row">
            <div className="grid_12">
              <div className="row lookbook-head look-desk">
                <div className="grid_4">
                  <div className="lookbook-logo"><NavLink to="/home" >
                    <svg width="106" height="17" viewBox="0 0 106 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7515 0.261536C14.9299 0.261536 16.0176 0.435895 16.924 0.784613C17.9211 1.13333 18.6463 1.65641 19.3714 2.35384C20.0966 3.05128 20.5498 3.83589 20.9124 4.79487C21.3656 5.75384 21.5469 6.8 21.5469 7.93333C21.5469 10.2 20.8217 12.0308 19.2808 13.4256C18.6463 14.0359 17.8305 14.4718 17.0147 14.8205C16.1989 15.1692 15.2018 15.3436 14.2954 15.3436H5.23096V0.261536H13.7515ZM14.0234 12.4667C14.6579 12.4667 15.2925 12.2923 15.927 11.9436C16.5615 11.5949 17.0147 11.159 17.4679 10.5487C18.0118 9.7641 18.2837 8.89231 18.2837 7.93333C18.2837 7.2359 18.1931 6.62564 17.9211 6.01538C17.7398 5.40513 17.3773 4.88205 17.0147 4.44615C16.6521 4.01025 16.1989 3.74872 15.7457 3.48718C15.2018 3.22564 14.6579 3.13846 14.0234 3.13846H8.31286V12.5538C8.31286 12.4667 14.0234 12.4667 14.0234 12.4667Z" fill="black" />
                      <path d="M28.4355 15.3436V0.261536H31.5174V12.4667H41.307V15.3436H28.4355Z" fill="black" />
                      <path d="M48.9212 15.3436V3.9231L46.927 4.88207V1.56925C47.6522 1.30771 48.3773 0.871813 48.9212 0.174377H52.0031V15.3436H48.9212Z" fill="black" />
                      <path d="M70.1319 8.19491C69.4974 8.80517 68.7722 9.24107 67.9564 9.5026C67.1406 9.76414 66.2342 9.9385 65.3278 9.9385C64.3307 9.9385 63.4242 9.85132 62.6991 9.58978C61.9739 9.32824 61.2488 9.06671 60.7049 8.63081C60.161 8.19491 59.7078 7.67183 59.4359 7.14876C59.164 6.5385 58.9827 5.92825 58.9827 5.31799C58.9827 4.53337 59.164 3.83594 59.4359 3.1385C59.7078 2.52824 60.161 1.91799 60.7049 1.48209C61.2488 1.04619 61.9739 0.697476 62.7897 0.435937C63.6055 0.174399 64.512 0.0872192 65.509 0.0872192C66.6874 0.0872192 67.7751 0.261578 68.6816 0.697476C69.6787 1.04619 70.4038 1.56927 71.129 2.26671C71.7635 2.96414 72.3073 3.74876 72.6699 4.70773C73.0325 5.66671 73.2138 6.71286 73.2138 7.84619C73.2138 8.71799 73.1231 9.5026 72.8512 10.2872C72.5793 11.0718 72.3073 11.7693 71.8541 12.3795C71.129 13.4257 70.2225 14.2103 69.1348 14.8206C68.0471 15.4308 66.8687 15.6923 65.509 15.6923C63.0616 15.6923 61.0675 14.7334 59.4359 12.8154L61.2488 10.9847C61.7926 11.5949 62.4271 12.0308 63.1523 12.3795C63.8774 12.7282 64.6026 12.8154 65.509 12.8154C66.1435 12.8154 66.7781 12.7282 67.3219 12.4667C67.8658 12.2923 68.319 11.9436 68.7722 11.5949C69.2254 11.2462 69.4974 10.7231 69.7693 10.1129C69.9506 9.5026 70.0412 8.89235 70.1319 8.19491ZM69.4974 4.96927C68.6816 3.66158 67.2313 3.05132 65.2371 3.05132C64.1494 3.05132 63.2429 3.22568 62.6991 3.5744C62.1552 3.92312 61.8833 4.44619 61.8833 5.05645C61.8833 6.36414 62.971 7.06158 64.9652 7.06158C67.05 7.06158 68.5003 6.36414 69.4974 4.96927Z" fill="black" />
                      <path d="M80.9187 7.84619C82.2783 6.5385 83.9099 5.84107 85.8135 5.84107C86.8105 5.84107 87.6263 5.92825 88.4421 6.18978C89.2579 6.45132 89.8924 6.71286 90.4363 7.14876C90.9802 7.58465 91.4334 8.02055 91.7053 8.63081C91.9773 9.24107 92.1585 9.85132 92.1585 10.5488C92.1585 11.3334 91.9773 11.9436 91.7053 12.6411C91.4334 13.3385 90.9802 13.7744 90.4363 14.2975C89.8924 14.7334 89.2579 15.0821 88.4421 15.3436C87.6263 15.6052 86.7199 15.6923 85.7228 15.6923C84.6351 15.6923 83.638 15.518 82.7316 15.1693C81.8251 14.8206 81.0093 14.2975 80.3748 13.6872C78.8339 12.2052 78.0181 10.2872 78.0181 7.93337C78.0181 6.18978 78.4713 4.62055 79.2871 3.40004C80.0122 2.35389 80.9187 1.56927 81.9158 0.959014C83.0035 0.348758 84.0912 0.0872192 85.3602 0.0872192C86.6293 0.0872192 87.717 0.348758 88.8047 0.871835C89.8924 1.39491 90.7989 2.17953 91.7053 3.22568L89.9831 5.14363C89.3486 4.44619 88.7141 3.92312 87.9889 3.66158C87.2638 3.40004 86.448 3.22568 85.5415 3.22568C84.1819 3.22568 83.0035 3.66158 82.1877 4.53337C81.3719 5.40517 81.0093 6.36414 80.9187 7.84619ZM81.6438 10.6359C82.4596 12.0308 83.8193 12.7282 85.7228 12.7282C86.9012 12.7282 87.717 12.5539 88.3515 12.2052C88.986 11.8565 89.2579 11.3334 89.2579 10.7231C89.2579 10.1129 88.986 9.58978 88.3515 9.24107C87.717 8.89235 86.9012 8.71799 85.7228 8.71799C84.907 8.71799 84.1819 8.89235 83.4567 9.24107C82.7316 9.5026 82.0971 10.0257 81.6438 10.6359Z" fill="black" />
                      <path d="M100.226 15.3436V3.9231L98.2317 4.88207V1.56925C98.9568 1.30771 99.682 0.871813 100.226 0.174377H103.308V15.3436H100.226Z" fill="black" />
                    </svg></NavLink>
                  </div>
                </div>
                <div className="grid_6">
                  <div className="lookbook-desc">
                    <p>Lookbook - Spring/Summer 2024 - Women</p>
                    <p>DL1961 is a premium essentials brand with roots in vertical integration. Offering elevated denim, knits, and ready-to-wear, for women, men, kids, and pets. We believe in meticulously crafted pieces designed to carry you through all of life’s stages.
                     <br/>
                    In addition to our own low-impact factory, we strategically partner with sustainable manufacturers around the world to produce high quality essentials perfect for everyday wear. Named one of Fast Company’s Most Innovative Companies in 2023, this press-loved brand is a perennial favorite of editors and celebrities alike.</p>
                  </div>
                </div>
                <div className="grid_2">
                  <div className="look-menu">
                    <a className="unlink">Lookbook</a>
                    <NavLink to="/Fitguide_SS24">Fit Guide</NavLink>
                    <NavLink to="/Fabrics_24">Fabrics</NavLink>
                    {/* <a target="_blank" href="https://www.dl1961.com/pages/press">Press</a> */}
                    <a target="_blank" href="https://www.dl1961.com/pages/our-sustainability-practices">Sustainability</a>
                    <a href={menPDF} target="_blank">Download PDF</a>
                    {/* Include the RadioButtonGroup component */}
                    <RadioButtonGroup />


                  </div>
                </div>
              </div>
              <div className="row lookbook-head look-mob">
                <div className="grid_12" style={{ position: "relative" }}>
                  <img src={main} />
                  <div className="lookbook-logo"><NavLink to="/home" >
                    <svg width="106" height="17" viewBox="0 0 106 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: "0 auto" }}>
                      <path d="M13.7515 0.261536C14.9299 0.261536 16.0176 0.435895 16.924 0.784613C17.9211 1.13333 18.6463 1.65641 19.3714 2.35384C20.0966 3.05128 20.5498 3.83589 20.9124 4.79487C21.3656 5.75384 21.5469 6.8 21.5469 7.93333C21.5469 10.2 20.8217 12.0308 19.2808 13.4256C18.6463 14.0359 17.8305 14.4718 17.0147 14.8205C16.1989 15.1692 15.2018 15.3436 14.2954 15.3436H5.23096V0.261536H13.7515ZM14.0234 12.4667C14.6579 12.4667 15.2925 12.2923 15.927 11.9436C16.5615 11.5949 17.0147 11.159 17.4679 10.5487C18.0118 9.7641 18.2837 8.89231 18.2837 7.93333C18.2837 7.2359 18.1931 6.62564 17.9211 6.01538C17.7398 5.40513 17.3773 4.88205 17.0147 4.44615C16.6521 4.01025 16.1989 3.74872 15.7457 3.48718C15.2018 3.22564 14.6579 3.13846 14.0234 3.13846H8.31286V12.5538C8.31286 12.4667 14.0234 12.4667 14.0234 12.4667Z" fill="white" />
                      <path d="M28.4355 15.3436V0.261536H31.5174V12.4667H41.307V15.3436H28.4355Z" fill="white" />
                      <path d="M48.9212 15.3436V3.9231L46.927 4.88207V1.56925C47.6522 1.30771 48.3773 0.871813 48.9212 0.174377H52.0031V15.3436H48.9212Z" fill="white" />
                      <path d="M70.1319 8.19491C69.4974 8.80517 68.7722 9.24107 67.9564 9.5026C67.1406 9.76414 66.2342 9.9385 65.3278 9.9385C64.3307 9.9385 63.4242 9.85132 62.6991 9.58978C61.9739 9.32824 61.2488 9.06671 60.7049 8.63081C60.161 8.19491 59.7078 7.67183 59.4359 7.14876C59.164 6.5385 58.9827 5.92825 58.9827 5.31799C58.9827 4.53337 59.164 3.83594 59.4359 3.1385C59.7078 2.52824 60.161 1.91799 60.7049 1.48209C61.2488 1.04619 61.9739 0.697476 62.7897 0.435937C63.6055 0.174399 64.512 0.0872192 65.509 0.0872192C66.6874 0.0872192 67.7751 0.261578 68.6816 0.697476C69.6787 1.04619 70.4038 1.56927 71.129 2.26671C71.7635 2.96414 72.3073 3.74876 72.6699 4.70773C73.0325 5.66671 73.2138 6.71286 73.2138 7.84619C73.2138 8.71799 73.1231 9.5026 72.8512 10.2872C72.5793 11.0718 72.3073 11.7693 71.8541 12.3795C71.129 13.4257 70.2225 14.2103 69.1348 14.8206C68.0471 15.4308 66.8687 15.6923 65.509 15.6923C63.0616 15.6923 61.0675 14.7334 59.4359 12.8154L61.2488 10.9847C61.7926 11.5949 62.4271 12.0308 63.1523 12.3795C63.8774 12.7282 64.6026 12.8154 65.509 12.8154C66.1435 12.8154 66.7781 12.7282 67.3219 12.4667C67.8658 12.2923 68.319 11.9436 68.7722 11.5949C69.2254 11.2462 69.4974 10.7231 69.7693 10.1129C69.9506 9.5026 70.0412 8.89235 70.1319 8.19491ZM69.4974 4.96927C68.6816 3.66158 67.2313 3.05132 65.2371 3.05132C64.1494 3.05132 63.2429 3.22568 62.6991 3.5744C62.1552 3.92312 61.8833 4.44619 61.8833 5.05645C61.8833 6.36414 62.971 7.06158 64.9652 7.06158C67.05 7.06158 68.5003 6.36414 69.4974 4.96927Z" fill="white" />
                      <path d="M80.9187 7.84619C82.2783 6.5385 83.9099 5.84107 85.8135 5.84107C86.8105 5.84107 87.6263 5.92825 88.4421 6.18978C89.2579 6.45132 89.8924 6.71286 90.4363 7.14876C90.9802 7.58465 91.4334 8.02055 91.7053 8.63081C91.9773 9.24107 92.1585 9.85132 92.1585 10.5488C92.1585 11.3334 91.9773 11.9436 91.7053 12.6411C91.4334 13.3385 90.9802 13.7744 90.4363 14.2975C89.8924 14.7334 89.2579 15.0821 88.4421 15.3436C87.6263 15.6052 86.7199 15.6923 85.7228 15.6923C84.6351 15.6923 83.638 15.518 82.7316 15.1693C81.8251 14.8206 81.0093 14.2975 80.3748 13.6872C78.8339 12.2052 78.0181 10.2872 78.0181 7.93337C78.0181 6.18978 78.4713 4.62055 79.2871 3.40004C80.0122 2.35389 80.9187 1.56927 81.9158 0.959014C83.0035 0.348758 84.0912 0.0872192 85.3602 0.0872192C86.6293 0.0872192 87.717 0.348758 88.8047 0.871835C89.8924 1.39491 90.7989 2.17953 91.7053 3.22568L89.9831 5.14363C89.3486 4.44619 88.7141 3.92312 87.9889 3.66158C87.2638 3.40004 86.448 3.22568 85.5415 3.22568C84.1819 3.22568 83.0035 3.66158 82.1877 4.53337C81.3719 5.40517 81.0093 6.36414 80.9187 7.84619ZM81.6438 10.6359C82.4596 12.0308 83.8193 12.7282 85.7228 12.7282C86.9012 12.7282 87.717 12.5539 88.3515 12.2052C88.986 11.8565 89.2579 11.3334 89.2579 10.7231C89.2579 10.1129 88.986 9.58978 88.3515 9.24107C87.717 8.89235 86.9012 8.71799 85.7228 8.71799C84.907 8.71799 84.1819 8.89235 83.4567 9.24107C82.7316 9.5026 82.0971 10.0257 81.6438 10.6359Z" fill="white" />
                      <path d="M100.226 15.3436V3.9231L98.2317 4.88207V1.56925C98.9568 1.30771 99.682 0.871813 100.226 0.174377H103.308V15.3436H100.226Z" fill="white" />
                    </svg></NavLink>

                  </div>
                  <div className="lookbook-desc">
                    <p>DL1961 is a premium essentials brand with roots in vertical integration. Offering elevated denim, knits, and ready-to-wear, for women, men, kids, and pets. We believe in meticulously crafted pieces designed to carry you through all of life’s stages.
                     <br/>
                    In addition to our own low-impact factory, we strategically partner with sustainable manufacturers around the world to produce high quality essentials perfect for everyday wear. Named one of Fast Company’s Most Innovative Companies in 2023, this press-loved brand is a perennial favorite of editors and celebrities alike.</p>
                  </div>
                </div>
                <div className="grid_12">
                  <div className="grid-menu">
                    <a className="unlink">Lookbook</a>
                    <NavLink to="/Fitguide_SS24">Fit Guide</NavLink>
                    <NavLink to="/Fabrics_24">Fabrics</NavLink>
                    {/* <a target="_blank" href="https://www.dl1961.com/pages/press">Press</a> */}
                    <a target="_blank" href="https://www.dl1961.com/pages/our-sustainability-practices">Sustainability</a>
                    <a href={menPDF} target="_blank">Download PDF</a>
                    <NavLink to="/home">Collections</NavLink>
                  </div>
                </div>


              </div>
            </div>
          </div>


          <div className="row look-desk">
            <div className="look-desk">
              <div className="row">
                <div className="grid-top">
                  <div className="images">
                    <img src={image1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-horizantal">
              <div>
                <div className="images">
                  <img className="hide-hover" src={image2} alt="" />
                  <img className="hover" src={image2hover} alt="" />
                  <div className="image-text">Alma Skirt in Black</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image3} alt="" />
                  <img className="hover" src={image3hover} alt="" />
                  <div className="image-text">Esme Dress in Lt. Fountain</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image4} alt="" />
                  <img className="hover" src={image4hover} alt="" />
                  <div className="image-text">Taylor Barrel in Vintage Light</div>
                </div>
              </div>


              <div>
                <div className="images">
                  <img className="hide-hover" src={image17} alt="" />
                  <img className="hover" src={image17hover} alt="" />
                  <div className="image-text">Andi Skirt in Lt Fountain and Vika Jacket in Fountain</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image18} alt="" />
                  <img className="hover" src={image18hover} alt="" />
                  <div className="image-text">Drue Straight 27” in Vapor</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image19} alt="" />
                  <img className="hover" src={image19hover} alt="" />
                  <div className="image-text">Lucila Short in Clear Sky</div>
                </div>
              </div>



              <div>
                <div className="images">
                  <img className="hide-hover" src={image29} alt="" />
                  <img className="hover" src={image29hover} alt="" />
                  <div className="image-text">Baby Tee in Cream and Hepburn Low Rise Wide Leg in Ravello Cuffed</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image30} alt="" />
                  <img className="hover" src={image30hover} alt="" />
                  <div className="image-text">Drue Straight in Fountain Shredded</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image31} alt="" />
                  <img className="hover" src={image31hover} alt="" />
                  <div className="image-text">Simone Top in White and Patti Straight in East Bay</div>
                </div>
              </div>



              <div>
                <div className="images">
                  <img className="hide-hover" src={image5} alt="" />
                  <img className="hover" src={image5hover} alt="" />
                  <div className="image-text">Hepburn Wide Leg in Black</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image6} alt="" />
                  <img className="hover" src={image6hover} alt="" />
                  <div className="image-text">Tank in Cream</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image7} alt="" />
                  <img className="hover" src={image7hover} alt="" />
                  <div className="image-text">Zoie Wide Leg in Cerulean and Turtleneck in Blue</div>
                </div>
              </div>



              <div>
                <div className="images">
                  <img className="hide-hover" src={image20} alt="" />
                  <img className="hover" src={image20hover} alt="" />
                  <div className="image-text">Esme Apron Dress in Fountain</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image21} alt="" />
                  <img className="hover" src={image21hover} alt="" />
                  <div className="image-text">Agni Wide Leg and Faye Shirt in Blue Poplin</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image22} alt="" />
                  <img className="hover" src={image22hover} alt="" />
                  <div className="image-text">Payton Mini Dress in Bright Mid</div>
                </div>
              </div>


            <div>
                <div className="images">
                  <img className="hide-hover" src={image32} alt="" />
                  <img className="hover" src={image32hover} alt="" />
                  <div className="image-text">Essential Cami and Boxer Short in Alpine Green</div>
                </div>
              </div>
            <div>
                <div className="images">
                  <img className="hide-hover" src={image33} alt="" />
                  <img className="hover" src={image33hover} alt="" />
                  <div className="image-text">Montauk Shirt and Boxer Short in White</div>
                </div>
              </div>
            <div>
                <div className="images">
                  <img className="hide-hover" src={image34} alt="" />
                  <img className="hover" src={image34hover} alt="" />
                  <div className="image-text">Essential Tee and Hepburn Wide Leg in Alpine Green</div>
                </div>
              </div>



              </div>


            <div className="grid-horizantal2">
              <div className="look-desk">
                <div className="images">
                  <img src={image8} alt="" />
                </div>
              </div>
              <div>
                <div className="images border-bottom">
                  <img className="hide-hover" src={image9} alt="" />
                  <img className="hover" src={image9hover} alt="" />
                  <div className="image-text pos-abs">Joni Slim Boot in Ecru</div>
                </div>
                <div className="images">
                  <img className="hide-hover" src={image10} alt="" />
                  <img className="hover" src={image10hover} alt="" />
                  <div className="image-text pos-abs">Alma Skirt in Black and Long Sleeve Boat Neck in Black</div>
                </div>
              </div>
            </div>




            <div className="grid-horizantal">
              <div>
                <div className="images">
                  <img className="hide-hover" src={image23} alt="" />
                  <img className="hover" src={image23hover} alt="" />
                  <div className="image-text">Zia Vest and Lucila Ultra Wide Leg in Flax Linen</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image24} alt="" />
                  <img className="hover" src={image24hover} alt="" />
                  <div className="image-text">Long Sleeve Boat Neck in Cream and Alma Mini Skirt in Ecru</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image25} alt="" />
                  <img className="hover" src={image25hover} alt="" />
                  <div className="image-text">Long Sleeve Boat Neck in Cream and Hepburn Wide Leg in Manila Knit</div>
                </div>
              </div>





              <div>
                <div className="images">
                  <img className="hide-hover" src={image35} alt="" />
                  <img className="hover" src={image35hover} alt="" />
                  <div className="image-text">Essential Tee in Manilla and Zoie Wide Leg in Poolside Cargo</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image36} alt="" />
                  <img className="hover" src={image36hover} alt="" />
                  <div className="image-text">Essential Tee and Hepburn Short in Manilla</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image37} alt="" />
                  <img className="hover" src={image37hover} alt="" />
                  <div className="image-text">Essential Tee in Manilla and Enora Cigarette Vintage Light</div>
                </div>
              </div>


              
              <div>
                <div className="images">
                  <img className="hide-hover" src={image11} alt="" />
                  <img className="hover" src={image11hover} alt="" />
                  <div className="image-text">Drue Straight in Daydream</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image12} alt="" />
                  <img className="hover" src={image12hover} alt="" />
                  <div className="image-text">Enora Cigarette in Vintage Light</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image13} alt="" />
                  <img className="hover" src={image13hover} alt="" />
                  <div className="image-text">Asra Skirt in Ecru and Lisette Shirt in Manilla</div>
                </div>
              </div>




              
              <div>
                <div className="images">
                  <img className="hide-hover" src={image26} alt="" />
                  <img className="hover" src={image26hover} alt="" />
                  <div className="image-text">Lucila Ultra Wide Leg in Clear Sky</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image27} alt="" />
                  <img className="hover" src={image27hover} alt="" />
                  <div className="image-text">Taylor Barrel in Sunbleached</div>
                </div>
              </div>
              <div>
                <div className="images">
                  <img className="hide-hover" src={image28} alt="" />
                  <img className="hover" src={image28hover} alt="" />
                  <div className="image-text">Baby Tee in White and Desi Relaxed Boot in Fountain</div>
                </div>
              </div>




              
            <div>
              <div className="images">
                <img className="hide-hover" src={image38} alt="" />
                <img className="hover" src={image38hover} alt="" />
                <div className="image-text">Esme Dress in White</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image39} alt="" />
                <img className="hover" src={image39hover} alt="" />
                <div className="image-text">Coletta Dress in White</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image40} alt="" />
                <img className="hover" src={image40hover} alt="" />
                <div className="image-text">Daphne Dress in White</div>
              </div>
            </div>
            </div>






            <div className="grid-horizantal3">
              <div>
                <div className="images border-bottom">
                  <img className="hide-hover" src={image14} alt="" />
                  <img className="hover" src={image14hover} alt="" />
                  <div className="image-text pos-abs">Long Sleeve Boat Neck in Black</div>
                </div>
                <div className="images">
                  <img className="hide-hover" src={image15} alt="" />
                  <img className="hover" src={image15hover} alt="" />
                  <div className="image-text pos-abs">Long Sleeve Boat Neck in Light Grey</div>
                </div>
              </div>
              <div className="look-desk">
                <div className="images">
                  <img src={image16} alt="" />
                </div>
              </div>
            </div>




            <div className="grid-horizantal">

            <div>
              <div className="images">
                <img className="hide-hover" src={image41} alt="" />
                <img className="hover" src={image41hover} alt="" />
                <div className="image-text">Baby Tee in Cream and Thea Boyfriend in Ravello</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image42} alt="" />
                <img className="hover" src={image42hover} alt="" />
                <div className="image-text">Zoie Short in Vapor</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image43} alt="" />
                <img className="hover" src={image43hover} alt="" />
                <div className="image-text">Essential Tee in Manilla and Mara Straight in Blue Current</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image44} alt="" />
                <img className="hover" src={image44hover} alt="" />
                <div className="image-text">Baby Tee in Cream and Hepburn Wide Leg in White</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image45} alt="" />
                <img className="hover" src={image45hover} alt="" />
                <div className="image-text">Gwen Jogger in Flax</div>
              </div>
            </div>
            <div>
              <div className="images">
                <img className="hide-hover" src={image46} alt="" />
                <img className="hover" src={image46hover} alt="" />
                <div className="image-text">Lucila Short in White</div>
              </div>
            </div>



            </div>




          </div>





          <div className="look-mob">
            <div className="grid-horizantal">

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image2} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image2hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Alma Skirt in Black</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image3} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image3hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Esme Dress in Lt. Fountain</div>
              </div>



              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image4} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image4hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Taylor Barrel in Vintage Light</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image17} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image17hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Andi Skirt in Lt Fountain and Vika Jacket in Fountain</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image18} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image18hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Drue Straight 27” in Vapor</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image19} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image19hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Lucila Short in Clear Sky</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image29} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image29hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Baby Tee in Cream and Hepburn Low Rise Wide Leg in Ravello Cuffed</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image30} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image30hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Drue Straight in Fountain Shredded</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image31} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image31hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Simone Top in White and Patti Straight in East Bay</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image5} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image5hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Hepburn Wide Leg in Black</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image6} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image6hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Tank in Cream</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image7} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image7hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Zoie Wide Leg in Cerulean and Turtleneck in Blue</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image20} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image20hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Esme Apron Dress in Fountain</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image21} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image21hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Agni Wide Leg and Faye Shirt in Blue Poplin</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image22} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image22hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Payton Mini Dress in Bright Mid</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image32} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image32hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Cami and Boxer Short in Alpine Green</div>
              </div>



              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image33} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image33hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Montauk Shirt and Boxer Short in White</div>
              </div>

              

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image34} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image34hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Tee and Hepburn Wide Leg in Alpine Green</div>
              </div>

              

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image9} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image9hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Joni Slim Boot in Ecru</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image10} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image10hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Turtleneck in Charocal</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image23} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image23hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Zia Vest and Lucila Ultra Wide Leg in Flax Linen</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image24} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image24hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Long Sleeve Boat Neck in Cream and Alma Mini Skirt in Ecru</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image25} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image25hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Long Sleeve Boat Neck in Cream and Hepburn Wide Leg in Manila Knit</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image35} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image35hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Tee in Manilla and Zoie Wide Leg in Poolside Cargo</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image36} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image36hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Tee and Hepburn Short in Manilla</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image37} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image37hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Tee in Manilla and Enora Cigarette Vintage Light</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image11} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image11hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Drue Straight in Daydream</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image12} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image12hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Enora Cigarette in Vintage Light</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image13} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image13hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Asra Skirt in Ecru and Lisette Shirt in Manilla</div>
              </div>



              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image26} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image26hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Lucila Ultra Wide Leg in Clear Sky</div>
              </div>



              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image27} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image27hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Taylor Barrel in Sunbleached</div>
              </div>



              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image28} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image28hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Baby Tee in White and Desi Relaxed Boot in Fountain</div>
              </div>

              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image38} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image38hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Esme Dress in White</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image39} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image39hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Coletta Dress in White</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image40} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image40hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Daphne Dress in White</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image14} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image14hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Long Sleeve Boat Neck in Black</div>
              </div>


              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image15} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image15hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Long Sleeve Boat Neck in Light Grey</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image41} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image41hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Baby Tee in Cream and Thea Boyfriend in Ravello</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image42} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image42hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Zoie Short in Vapor</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image43} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image43hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Essential Tee in Manilla and Mara Straight in Blue Current</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image44} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image44hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Baby Tee in Cream and Hepburn Wide Leg in White</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image45} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image45hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Gwen Jogger in Flax</div>
              </div>
              <div>
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={0}
                  slidesPerView={1}
                  pagination={{ clickable: true }}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide><img src={image46} alt="" /></SwiperSlide>
                  <SwiperSlide><img src={image46hover} alt="" /> </SwiperSlide>
                </Swiper>
                <div className="image-text fit-text">Lucila Short in White</div>
              </div>



            </div>
          </div>





          <div className="grid-footer" style={{border:"none"}}>

            <div>
              <p>For sales inquiries please contact <a href="mailto:sales@dl1961.com">sales@dl1961.com</a>.</p> <p>For press inquiries please contact <a href="mailto:press@dl1961.com">press@dl1961.com</a>.</p>
            </div>

            <div>
              <p>dl1961.com</p> <p>@dl1961denim</p>
            </div>

          </div>





        </div>
      </section>
    </>

  )
}
