// import { Navigate } from "react-router-dom";
// export default ({ children }) => {
//     let userInfo = localStorage.getItem("userInfo");
//     if (!userInfo) {
//         return <Navigate to="/" />;
//     }
//     return children;
// };



import React from 'react';
import { Redirect } from 'react-router-dom';

export default ({ children }) => {
  let userInfo = sessionStorage.getItem("userInfo");
  if (!userInfo) {
    return <Redirect to="/" />;
  }
  return children;
};
