import React from "react";
import "antd/dist/antd.variable.min.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  // Link
  useHistory
} from "react-router-dom";

import Password from './screens/Password';
import Home from './screens/Home';
// Women Tab
import Women_ss23 from "./screens/women_ss23";
import Women_ss24 from "./screens/women_ss24";
import Women_fw23 from "./screens/women_fw23";
import Women_resort24 from "./screens/women_resort24";
// Mens Tab 
import Men_fw23 from "./screens/men_fw23";
import Men_ss23 from "./screens/men_ss23";
import Men_ss24 from "./screens/men_ss24";
// Kids Tab
import Kids from "./screens/kids";
// Inner Pages
import Fitguide from "./screens/Fitguide";
import Fitguide_SS24 from "./screens/Fitguide_SS24";
import Fitguide_FW24 from "./screens/Fitguide_FW24";
import Fabrics from "./screens/Fabrics";
import Fabrics_24 from "./screens/Fabrics_24";
import Fitguidemen from "./screens/Fitguidemen";
import Fabricsmen from "./screens/Fabricsmen";
import Fabricsmen_24 from "./screens/Fabricsmen_24";
import Product from "./screens/Product";
import Women from "./screens/Women";
import Men from "./screens/Men";
import PrivateRoute from '../src/pages/ProtectedRoute'

import NotFound from "./screens/NotFound";
function App() {
  const history = useHistory();

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Password} />
          <PrivateRoute path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/Women_ss23">
            <Women_ss23 />
          </PrivateRoute>
          <PrivateRoute path="/Women_fw23">
            <Women_fw23 />
          </PrivateRoute>
          <PrivateRoute path="/Women_resort24">
            <Women_resort24 />
          </PrivateRoute>
          <PrivateRoute path="/Women_ss24">
            <Women_ss24 />
          </PrivateRoute>
          <PrivateRoute path="/Women">
            <Women />
          </PrivateRoute>
          <PrivateRoute path="/Men_fw23">
            <Men_fw23 />
          </PrivateRoute>
          <PrivateRoute path="/Men_ss23">
            <Men_ss23 />
          </PrivateRoute>
          <PrivateRoute path="/Men_ss24">
            <Men_ss24 />
          </PrivateRoute>
          <PrivateRoute path="/Kids">
            <Kids />
          </PrivateRoute>
          <PrivateRoute path="/Fitguide">
            <Fitguide />
          </PrivateRoute>
          <PrivateRoute path="/Fitguide_SS24">
            <Fitguide_SS24 />
          </PrivateRoute>
          <PrivateRoute path="/Fitguide_FW24">
            <Fitguide_FW24 />
          </PrivateRoute>

          <PrivateRoute path="/Fabrics">
            <Fabrics />
          </PrivateRoute>

          <PrivateRoute path="/Fabrics_24">
            <Fabrics_24 />
          </PrivateRoute>

          <PrivateRoute path="/Fitguidemen">
            <Fitguidemen />
          </PrivateRoute>
          <PrivateRoute path="/Fabricsmen">
            <Fabricsmen />
          </PrivateRoute>
          <PrivateRoute path="/Fabricsmen_24">
            <Fabricsmen_24 />
          </PrivateRoute>
          <Route exact={true} path="*" component={NotFound} />
        </Switch>
        {/* </Routes> */}
      </Router>

    </>
  );
}

export default App;
