import React, { useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import { Button, Checkbox, Form, Input, Alert } from 'antd';


import './Home.css';
export default function Password() {
    const history = useHistory()
    const { id } = useParams();
    const [loggedIn, setLoggedIn] = useState(true)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    const onFinish = (values) => {
        if (values.password === "dl23") {
            setPassword(values.password)
            sessionStorage.setItem("userInfo", JSON.stringify(values.password))
            history.push("/home")
            setLoggedIn(false)
        }
        else setError(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            {loggedIn ?
                <div style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}  >

                    <Form
                        name="basic"

                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >

                        <div className="ant-form-item"> Please Enter Password </div>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 0,
                                span: 24,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                        {error && <Alert message="Please Enter correct password" type="error" />
                        }
                    </Form>
                </div>
                : <div>


                </div>
            }

        </>

    )
}







