import React from 'react'
import {NavLink} from "react-router-dom"

import main from "../images/men/fitguide/main-w.jpg";

import image1 from "../images/men/fabric/DLPerformance01.jpg";
import image2 from "../images/men/fabric/DLPerformance02.jpg";
import image3 from "../images/men/fabric/fabric-men-Seaside.jpg";
import image4 from "../images/men/fabric/fabric-men-BarHarbor.jpg";
import image5 from "../images/men/fabric/fabric-men-Worthing.jpg";
import image6 from "../images/men/fabric/fabric-men-Anchorage.jpg";
import image7 from "../images/men/fabric/DLUltimateKnit01.jpg";
import image8 from "../images/men/fabric/DLUltimateKnit02.jpg";
import image9 from "../images/men/fabric/fabric-men-Howler.jpg";
import image10 from "../images/men/fabric/fabric-men-Stream.jpg";
import image11 from "../images/men/fabric/fabric-men-Starship.jpg";
import image12 from "../images/men/fabric/fabric-men-Cavern.jpg";
import image13 from "../images/men/fabric/DLUltimateKnitColors01.jpg";
import image14 from "../images/men/fabric/DLUltimateKnitColors02.jpg";
import image15 from "../images/men/fabric/fabric-men-Alder.jpg";
import image16 from "../images/men/fabric/fabric-men-Camel.jpg";
import image17 from "../images/men/fabric/fabric-men-Spruce.jpg";
import image18 from "../images/men/fabric/fabric-men-Anthractie.jpg";
import image19 from "../images/men/fabric/DLUltimate01.jpg";
import image20 from "../images/men/fabric/DLUltimate02.jpg";
import image21 from "../images/men/fabric/fabric-men-HighTower.jpg";
import image22 from "../images/men/fabric/fabric-men-Belize.jpg";
import image23 from "../images/men/fabric/fabric-men-Sable.jpg";
import image24 from "../images/men/fabric/fabric-men-Roman.jpg";
//import image1mob from "../images/men/fabric/skinny-tapered01-fitguide23-mobile.jpg";
import image2mob from "../images/men/fabric/DLPerformance02-mobile.jpg";

import image3mob from "../images/men/fabric/fabric-men-Seaside-mobile.jpg";
import image4mob from "../images/men/fabric/fabric-men-BarHarbor-mobile.jpg";
import image5mob from "../images/men/fabric/fabric-men-Worthing-mobile.jpg";
import image6mob from "../images/men/fabric/fabric-men-Anchorage-mobile.jpg";
import image7mob from "../images/men/fabric/DLUltimateKnit01-mobile.jpg";
//import image8mob from "../images/men/fabric/DLUltimateKnit02-mobile.jpg";
import image9mob from "../images/men/fabric/fabric-men-Howler-mobile.jpg";
import image10mob from "../images/men/fabric/fabric-men-Stream-mobile.jpg";
import image11mob from "../images/men/fabric/fabric-men-Starship-mobile.jpg";
import image12mob from "../images/men/fabric/fabric-men-Cavern-mobile.jpg";
import image13mob from "../images/men/fabric/DLUltimateKnitColors01-mobile.jpg";
//import image14mob from "../images/men/fabric/DLUltimateKnitColors02-mobile.jpg";
import image15mob from "../images/men/fabric/fabric-men-Alder-mobile.jpg";
import image16mob from "../images/men/fabric/fabric-men-Camel-mobile.jpg";
import image17mob from "../images/men/fabric/fabric-men-Spruce-mobile.jpg";
import image18mob from "../images/men/fabric/fabric-men-Anthractie-mobile.jpg";
import image19mob from "../images/men/fabric/DLUltimate01-mobile.jpg";
//import image20mob from "../images/men/fabric/DLUltimate02-mobile.jpg";
import image21mob from "../images/men/fabric/fabric-men-HighTower-mobile.jpg";
import image22mob from "../images/men/fabric/fabric-men-Belize-mobile.jpg";
import image23mob from "../images/men/fabric/fabric-men-Sable-mobile.jpg";
import image24mob from "../images/men/fabric/fabric-men-Roman-mobile.jpg";
import './Home.css';

export default function Fabricsmen() {
  // const { id } = useParams();
  // const [loggedIn, setLoggedIn] = useState(true)
  // const [password, setPassword] = useState('')
  // const [error, setError] = useState(false)

  // const onFinish = (values) => {
  //   if (values.password === "dl23") {
  //     setPassword(values.password)
  //     setLoggedIn(false)
  //   }
  //   else setError(true);
  // };

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo);
  // };
  return (
    <>

      <section id="content">
        <div className="container-fluid">
          <div className="row">
            <div className="grid_12">
              <div className="row lookbook-head look-desk">
                <div className="grid_4">
                  <div className="lookbook-logo"><NavLink to="/home" >
                    <svg width="106" height="17" viewBox="0 0 106 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7515 0.261536C14.9299 0.261536 16.0176 0.435895 16.924 0.784613C17.9211 1.13333 18.6463 1.65641 19.3714 2.35384C20.0966 3.05128 20.5498 3.83589 20.9124 4.79487C21.3656 5.75384 21.5469 6.8 21.5469 7.93333C21.5469 10.2 20.8217 12.0308 19.2808 13.4256C18.6463 14.0359 17.8305 14.4718 17.0147 14.8205C16.1989 15.1692 15.2018 15.3436 14.2954 15.3436H5.23096V0.261536H13.7515ZM14.0234 12.4667C14.6579 12.4667 15.2925 12.2923 15.927 11.9436C16.5615 11.5949 17.0147 11.159 17.4679 10.5487C18.0118 9.7641 18.2837 8.89231 18.2837 7.93333C18.2837 7.2359 18.1931 6.62564 17.9211 6.01538C17.7398 5.40513 17.3773 4.88205 17.0147 4.44615C16.6521 4.01025 16.1989 3.74872 15.7457 3.48718C15.2018 3.22564 14.6579 3.13846 14.0234 3.13846H8.31286V12.5538C8.31286 12.4667 14.0234 12.4667 14.0234 12.4667Z" fill="black" />
                      <path d="M28.4355 15.3436V0.261536H31.5174V12.4667H41.307V15.3436H28.4355Z" fill="black" />
                      <path d="M48.9212 15.3436V3.9231L46.927 4.88207V1.56925C47.6522 1.30771 48.3773 0.871813 48.9212 0.174377H52.0031V15.3436H48.9212Z" fill="black" />
                      <path d="M70.1319 8.19491C69.4974 8.80517 68.7722 9.24107 67.9564 9.5026C67.1406 9.76414 66.2342 9.9385 65.3278 9.9385C64.3307 9.9385 63.4242 9.85132 62.6991 9.58978C61.9739 9.32824 61.2488 9.06671 60.7049 8.63081C60.161 8.19491 59.7078 7.67183 59.4359 7.14876C59.164 6.5385 58.9827 5.92825 58.9827 5.31799C58.9827 4.53337 59.164 3.83594 59.4359 3.1385C59.7078 2.52824 60.161 1.91799 60.7049 1.48209C61.2488 1.04619 61.9739 0.697476 62.7897 0.435937C63.6055 0.174399 64.512 0.0872192 65.509 0.0872192C66.6874 0.0872192 67.7751 0.261578 68.6816 0.697476C69.6787 1.04619 70.4038 1.56927 71.129 2.26671C71.7635 2.96414 72.3073 3.74876 72.6699 4.70773C73.0325 5.66671 73.2138 6.71286 73.2138 7.84619C73.2138 8.71799 73.1231 9.5026 72.8512 10.2872C72.5793 11.0718 72.3073 11.7693 71.8541 12.3795C71.129 13.4257 70.2225 14.2103 69.1348 14.8206C68.0471 15.4308 66.8687 15.6923 65.509 15.6923C63.0616 15.6923 61.0675 14.7334 59.4359 12.8154L61.2488 10.9847C61.7926 11.5949 62.4271 12.0308 63.1523 12.3795C63.8774 12.7282 64.6026 12.8154 65.509 12.8154C66.1435 12.8154 66.7781 12.7282 67.3219 12.4667C67.8658 12.2923 68.319 11.9436 68.7722 11.5949C69.2254 11.2462 69.4974 10.7231 69.7693 10.1129C69.9506 9.5026 70.0412 8.89235 70.1319 8.19491ZM69.4974 4.96927C68.6816 3.66158 67.2313 3.05132 65.2371 3.05132C64.1494 3.05132 63.2429 3.22568 62.6991 3.5744C62.1552 3.92312 61.8833 4.44619 61.8833 5.05645C61.8833 6.36414 62.971 7.06158 64.9652 7.06158C67.05 7.06158 68.5003 6.36414 69.4974 4.96927Z" fill="black" />
                      <path d="M80.9187 7.84619C82.2783 6.5385 83.9099 5.84107 85.8135 5.84107C86.8105 5.84107 87.6263 5.92825 88.4421 6.18978C89.2579 6.45132 89.8924 6.71286 90.4363 7.14876C90.9802 7.58465 91.4334 8.02055 91.7053 8.63081C91.9773 9.24107 92.1585 9.85132 92.1585 10.5488C92.1585 11.3334 91.9773 11.9436 91.7053 12.6411C91.4334 13.3385 90.9802 13.7744 90.4363 14.2975C89.8924 14.7334 89.2579 15.0821 88.4421 15.3436C87.6263 15.6052 86.7199 15.6923 85.7228 15.6923C84.6351 15.6923 83.638 15.518 82.7316 15.1693C81.8251 14.8206 81.0093 14.2975 80.3748 13.6872C78.8339 12.2052 78.0181 10.2872 78.0181 7.93337C78.0181 6.18978 78.4713 4.62055 79.2871 3.40004C80.0122 2.35389 80.9187 1.56927 81.9158 0.959014C83.0035 0.348758 84.0912 0.0872192 85.3602 0.0872192C86.6293 0.0872192 87.717 0.348758 88.8047 0.871835C89.8924 1.39491 90.7989 2.17953 91.7053 3.22568L89.9831 5.14363C89.3486 4.44619 88.7141 3.92312 87.9889 3.66158C87.2638 3.40004 86.448 3.22568 85.5415 3.22568C84.1819 3.22568 83.0035 3.66158 82.1877 4.53337C81.3719 5.40517 81.0093 6.36414 80.9187 7.84619ZM81.6438 10.6359C82.4596 12.0308 83.8193 12.7282 85.7228 12.7282C86.9012 12.7282 87.717 12.5539 88.3515 12.2052C88.986 11.8565 89.2579 11.3334 89.2579 10.7231C89.2579 10.1129 88.986 9.58978 88.3515 9.24107C87.717 8.89235 86.9012 8.71799 85.7228 8.71799C84.907 8.71799 84.1819 8.89235 83.4567 9.24107C82.7316 9.5026 82.0971 10.0257 81.6438 10.6359Z" fill="black" />
                      <path d="M100.226 15.3436V3.9231L98.2317 4.88207V1.56925C98.9568 1.30771 99.682 0.871813 100.226 0.174377H103.308V15.3436H100.226Z" fill="black" />
                    </svg></NavLink>
                  </div>
                </div>
                <div className="grid_6">
                  <div className="lookbook-desc">
              <p>Fabrics</p>
              <p>High performance denim made from previously loved knits, undies, jeans and everything in between. Manufactured in one vertically integrated facility using recycled water, solar power and resource efficient technologies. This means more accountability and transparency, and less miles traveled to make your favorite pair of jeans.</p>
                  </div>
                </div>
                <div className="grid_2">
                  <div className="look-menu">
                    <NavLink to="/home">Lookbook</NavLink>
                    <NavLink to="/Fitguidemen">Fit Guide</NavLink>
                    <a className="unlink">Fabrics</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.dl1961.com/pages/press">Press</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.dl1961.com/pages/our-sustainability-practices">Sustainability</a>
                  </div>
                </div>
              </div>
              <div className="row lookbook-head look-mob">
                <div className="grid_12" style={{ position: "relative" }}>
                  <img src={main} alt='' />
                  <div className="lookbook-logo">
                  <NavLink to="/home" >
                  <svg width="106" height="17" viewBox="0 0 106 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7515 0.261536C14.9299 0.261536 16.0176 0.435895 16.924 0.784613C17.9211 1.13333 18.6463 1.65641 19.3714 2.35384C20.0966 3.05128 20.5498 3.83589 20.9124 4.79487C21.3656 5.75384 21.5469 6.8 21.5469 7.93333C21.5469 10.2 20.8217 12.0308 19.2808 13.4256C18.6463 14.0359 17.8305 14.4718 17.0147 14.8205C16.1989 15.1692 15.2018 15.3436 14.2954 15.3436H5.23096V0.261536H13.7515ZM14.0234 12.4667C14.6579 12.4667 15.2925 12.2923 15.927 11.9436C16.5615 11.5949 17.0147 11.159 17.4679 10.5487C18.0118 9.7641 18.2837 8.89231 18.2837 7.93333C18.2837 7.2359 18.1931 6.62564 17.9211 6.01538C17.7398 5.40513 17.3773 4.88205 17.0147 4.44615C16.6521 4.01025 16.1989 3.74872 15.7457 3.48718C15.2018 3.22564 14.6579 3.13846 14.0234 3.13846H8.31286V12.5538C8.31286 12.4667 14.0234 12.4667 14.0234 12.4667Z" fill="black" />
                      <path d="M28.4355 15.3436V0.261536H31.5174V12.4667H41.307V15.3436H28.4355Z" fill="black" />
                      <path d="M48.9212 15.3436V3.9231L46.927 4.88207V1.56925C47.6522 1.30771 48.3773 0.871813 48.9212 0.174377H52.0031V15.3436H48.9212Z" fill="black" />
                      <path d="M70.1319 8.19491C69.4974 8.80517 68.7722 9.24107 67.9564 9.5026C67.1406 9.76414 66.2342 9.9385 65.3278 9.9385C64.3307 9.9385 63.4242 9.85132 62.6991 9.58978C61.9739 9.32824 61.2488 9.06671 60.7049 8.63081C60.161 8.19491 59.7078 7.67183 59.4359 7.14876C59.164 6.5385 58.9827 5.92825 58.9827 5.31799C58.9827 4.53337 59.164 3.83594 59.4359 3.1385C59.7078 2.52824 60.161 1.91799 60.7049 1.48209C61.2488 1.04619 61.9739 0.697476 62.7897 0.435937C63.6055 0.174399 64.512 0.0872192 65.509 0.0872192C66.6874 0.0872192 67.7751 0.261578 68.6816 0.697476C69.6787 1.04619 70.4038 1.56927 71.129 2.26671C71.7635 2.96414 72.3073 3.74876 72.6699 4.70773C73.0325 5.66671 73.2138 6.71286 73.2138 7.84619C73.2138 8.71799 73.1231 9.5026 72.8512 10.2872C72.5793 11.0718 72.3073 11.7693 71.8541 12.3795C71.129 13.4257 70.2225 14.2103 69.1348 14.8206C68.0471 15.4308 66.8687 15.6923 65.509 15.6923C63.0616 15.6923 61.0675 14.7334 59.4359 12.8154L61.2488 10.9847C61.7926 11.5949 62.4271 12.0308 63.1523 12.3795C63.8774 12.7282 64.6026 12.8154 65.509 12.8154C66.1435 12.8154 66.7781 12.7282 67.3219 12.4667C67.8658 12.2923 68.319 11.9436 68.7722 11.5949C69.2254 11.2462 69.4974 10.7231 69.7693 10.1129C69.9506 9.5026 70.0412 8.89235 70.1319 8.19491ZM69.4974 4.96927C68.6816 3.66158 67.2313 3.05132 65.2371 3.05132C64.1494 3.05132 63.2429 3.22568 62.6991 3.5744C62.1552 3.92312 61.8833 4.44619 61.8833 5.05645C61.8833 6.36414 62.971 7.06158 64.9652 7.06158C67.05 7.06158 68.5003 6.36414 69.4974 4.96927Z" fill="black" />
                      <path d="M80.9187 7.84619C82.2783 6.5385 83.9099 5.84107 85.8135 5.84107C86.8105 5.84107 87.6263 5.92825 88.4421 6.18978C89.2579 6.45132 89.8924 6.71286 90.4363 7.14876C90.9802 7.58465 91.4334 8.02055 91.7053 8.63081C91.9773 9.24107 92.1585 9.85132 92.1585 10.5488C92.1585 11.3334 91.9773 11.9436 91.7053 12.6411C91.4334 13.3385 90.9802 13.7744 90.4363 14.2975C89.8924 14.7334 89.2579 15.0821 88.4421 15.3436C87.6263 15.6052 86.7199 15.6923 85.7228 15.6923C84.6351 15.6923 83.638 15.518 82.7316 15.1693C81.8251 14.8206 81.0093 14.2975 80.3748 13.6872C78.8339 12.2052 78.0181 10.2872 78.0181 7.93337C78.0181 6.18978 78.4713 4.62055 79.2871 3.40004C80.0122 2.35389 80.9187 1.56927 81.9158 0.959014C83.0035 0.348758 84.0912 0.0872192 85.3602 0.0872192C86.6293 0.0872192 87.717 0.348758 88.8047 0.871835C89.8924 1.39491 90.7989 2.17953 91.7053 3.22568L89.9831 5.14363C89.3486 4.44619 88.7141 3.92312 87.9889 3.66158C87.2638 3.40004 86.448 3.22568 85.5415 3.22568C84.1819 3.22568 83.0035 3.66158 82.1877 4.53337C81.3719 5.40517 81.0093 6.36414 80.9187 7.84619ZM81.6438 10.6359C82.4596 12.0308 83.8193 12.7282 85.7228 12.7282C86.9012 12.7282 87.717 12.5539 88.3515 12.2052C88.986 11.8565 89.2579 11.3334 89.2579 10.7231C89.2579 10.1129 88.986 9.58978 88.3515 9.24107C87.717 8.89235 86.9012 8.71799 85.7228 8.71799C84.907 8.71799 84.1819 8.89235 83.4567 9.24107C82.7316 9.5026 82.0971 10.0257 81.6438 10.6359Z" fill="black" />
                      <path d="M100.226 15.3436V3.9231L98.2317 4.88207V1.56925C98.9568 1.30771 99.682 0.871813 100.226 0.174377H103.308V15.3436H100.226Z" fill="black" />
                    </svg></NavLink>
                  </div>
                  <div className="lookbook-desc fit-desc"> 
                  <p>Fabrics</p>
                  <p>High performance denim made from previously loved knits, undies, jeans and everything in between. Manufactured in one vertically integrated facility using recycled water, solar power and resource efficient technologies. This means more accountability and transparency, and less miles traveled to make your favorite pair of jeans.</p>
                  </div>
                </div>
                <div className="grid_12">
                  <div className="grid-menu">
                    <NavLink to="/home">Lookbook</NavLink>
                    <NavLink to="/Fitguidemen">Fit Guide</NavLink>
                    <a className="unlink">Fabrics</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.dl1961.com/pages/press">Press</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.dl1961.com/pages/our-sustainability-practices">Sustainability</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
 
 

          <div>
              <div className="grid-horizantal4 border-top-desk">
                <div>
                  <div className="images look-desk">
                    <img src={image1} alt="" />
                  </div>
                </div>
                <div>
                  <div className="images">
                    <img className="look-desk" src={image2} alt="" />
                    <img className="look-mob" src={image2mob} alt="" />
                  </div>
                </div>
              </div>
                   <div className="grid-horizantal4-image-text">DL Performance</div>
        </div>

        <div className="grid-horizantal5">
          <div>
            <div className="images">
              <img className="look-desk" src={image3} alt="" />
              <img className="look-mob" src={image3mob} alt="" />
              <div className="grid-horizantal5-image-text">Seaside</div>
            </div>
          </div>
          <div>
            <div className="images">
              <img className="look-desk" src={image4} alt="" />
              <img className="look-mob" src={image4mob} alt="" />
            <div className="grid-horizantal5-image-text">Bar Harbor</div>
            </div>
          </div>
          <div>                
            <div className="images">
              <img className="look-desk" src={image5} alt="" />
              <img className="look-mob" src={image5mob} alt="" />
            <div className="grid-horizantal5-image-text">Worthing</div>
            </div>
          </div>
          <div>
            <div className="images">
              <img className="look-desk" src={image6} alt="" />
              <img className="look-mob" src={image6mob} alt="" />
            <div className="grid-horizantal5-image-text">Anchorage</div>
            </div>
          </div>
        </div>

        <div>
              <div className="grid-horizantal4">
                <div>
                  <div className="images">
                    <img className="look-desk" src={image7} alt="" />
                    <img className="look-mob" src={image7mob} alt="" />
                  </div>
                </div>
                <div>
                  <div className="images look-desk">
                    <img src={image8} alt="" />
                  </div>
                </div>
                </div>
                    <div className="grid-horizantal4-image-text">DL Ultimate Knit, Classic Denim Washes</div>
              </div>

              <div className="grid-horizantal5">
                <div>
                  <div className="images">
                    <img className="look-desk" src={image9} alt="" />
                    <img className="look-mob" src={image9mob} alt="" />
                    <div className="grid-horizantal5-image-text">Howler</div>
                  </div>
                </div>
                <div>
                  <div className="images">
                    <img className="look-desk" src={image10} alt="" />
                    <img className="look-mob" src={image10mob} alt="" />
                  <div className="grid-horizantal5-image-text">Stream</div>
                  </div>
                </div>
                <div>                
                  <div className="images">
                    <img className="look-desk" src={image11} alt="" />
                    <img className="look-mob" src={image11mob} alt="" />
                  <div className="grid-horizantal5-image-text">Starship</div>
                  </div>
                </div>
                <div>
                  <div className="images">
                    <img className="look-desk" src={image12} alt="" />
                    <img className="look-mob" src={image12mob} alt="" />
                  <div className="grid-horizantal5-image-text">Cavern</div>
                  </div>
                </div>
              </div>
              <div>
                    <div className="grid-horizantal4">
                <div>
                  <div className="images">
                    <img className="look-desk" src={image13} alt="" />
                    <img className="look-mob" src={image13mob} alt="" />
                  </div>
                </div>
                <div>
                  <div className="images look-desk">
                    <img src={image14} alt="" />
                  </div>
                </div>
                </div>
                    <div className="grid-horizantal4-image-text">DL Ultimate Knit, Colors</div>
              </div>

              <div className="grid-horizantal5">
                <div>
                  <div className="images">
                    <img className="look-desk" src={image15} alt="" />
                    <img className="look-mob" src={image15mob} alt="" />
                    <div className="grid-horizantal5-image-text">Alder</div>
                  </div>
                </div>
                <div>
                  <div className="images">
                    <img className="look-desk" src={image16} alt="" />
                    <img className="look-mob" src={image16mob} alt="" />
                  <div className="grid-horizantal5-image-text">Camel</div>
                  </div>
                </div>
                <div>                
                  <div className="images">
                    <img className="look-desk" src={image17} alt="" />
                    <img className="look-mob" src={image17mob} alt="" />
                  <div className="grid-horizantal5-image-text">Spruce</div>
                  </div>
                </div>
                <div>
                  <div className="images">
                    <img className="look-desk" src={image18} alt="" />
                    <img className="look-mob" src={image18mob} alt="" />
                  <div className="grid-horizantal5-image-text">Anthractie</div>
                  </div>
                </div>
              </div>

        <div>
          <div className="grid-horizantal4">
            <div>
              <div className="images">
                <img className="look-desk" src={image19} alt="" />
                <img className="look-mob" src={image19mob} alt="" />
              </div>
            </div>
            <div>
              <div className="images look-desk">
                <img src={image20} alt="" />
              </div>
            </div>
          </div>
          <div className="grid-horizantal4-image-text">DL Ultimate™</div>
        </div>


        <div className="grid-horizantal5">
          <div>
            <div className="images">
              <img className="look-desk" src={image21} alt="" />
              <img className="look-mob" src={image21mob} alt="" />
              <div className="grid-horizantal5-image-text">High Tower</div>
            </div>
          </div>
          <div>
            <div className="images">
              <img className="look-desk" src={image22} alt="" />
              <img className="look-mob" src={image22mob} alt="" />
            <div className="grid-horizantal5-image-text">Belize</div>
            </div>
          </div>
          <div>                
            <div className="images">
              <img className="look-desk" src={image23} alt="" />
              <img className="look-mob" src={image23mob} alt="" />
            <div className="grid-horizantal5-image-text">Sable</div>
            </div>
          </div>
          <div>
            <div className="images">
              <img className="look-desk" src={image24} alt="" />
              <img className="look-mob" src={image24mob} alt="" />
            <div className="grid-horizantal5-image-text">Roman</div>
            </div>
          </div>
        </div> 
          </div>



        </div>
      </section>
    </>

  )
}
