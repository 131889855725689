import React from 'react'
import { Link, } from "react-router-dom";
import { Button } from 'antd';
import { HomeOutlined } from "@ant-design/icons"
import "./notFound.css"
export default function NotFound() {
    return (
        <div className='notfoundContainer'>
            <h1>404</h1>
            <p>Sorry, the page you visited does not exist.</p>
            <Button type='primary' ><Link to="/" className='d-flex align-items-center' ><HomeOutlined className='not-found-icon' /> Home</Link></Button>
        </div>
    )
}
