import React, { Component } from 'react';
import { NavLink } from "react-router-dom"

class ToggleParagraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isParagraphVisible: false,
    };
  }

  handleButtonClick = () => {
    this.setState((prevState) => ({
      isParagraphVisible: !prevState.isParagraphVisible,
    }));
  };

  render() {
// Define a CSS class based on the value of isParagraphVisible
const buttonClass = this.state.isParagraphVisible ? 'active-button' : '';


    return (
      <div class="ss-menu" id={buttonClass} >
        <div onClick={this.handleButtonClick}>Collections <div class="cross"></div></div>
        {this.state.isParagraphVisible && <p class="ssmenu"><NavLink to="/Women_fw23" >Women - Fall/Winter 23</NavLink> <NavLink to="/Women_ss24">Women - Spring/Summer 24</NavLink><NavLink to="/Men_fw23">Men - Fall/Winter 23</NavLink>  <NavLink to="/Men_ss24">Men - Spring/Summer 24</NavLink> <NavLink to="/Kids">Kids</NavLink></p>}
      </div>
    );
  }
}

export default ToggleParagraph;
